/* eslint-disable no-param-reassign */

import {
  getEventTarget,
  selectMonth,
  selectWeek,
  getDaysInMonthRange,
  getDaysInWeekRange,
  addClassToDaysRange,
} from './datePickerPluginUtils'

function SpreaadDatePickerPlugin(period) {
  return fp => {
    /**
     * Remove the hover class from all days
     */
    function clearHover() {
      const days = fp.days.childNodes
      days.forEach(day => day.classList.remove('inRange'))
    }

    /**
     * Add the hover class to all days in the range
     */
    function highlightOnDayHover(event) {
      const day = getEventTarget(event)
      if (!day.classList.contains('flatpickr-day')) return

      clearHover()
      if (fp.loadedPeriod === 'week') {
        const days = fp.days.childNodes

        const dayIndSeven = day.$i / 7
        const weekStartDay = days[7 * Math.floor(dayIndSeven)].dateObj
        const weekEndDay = days[7 * Math.ceil(dayIndSeven + 0.01) - 1].dateObj

        const weekDays = getDaysInWeekRange(fp, weekStartDay, weekEndDay)
        addClassToDaysRange(weekDays, ['inRange'])
      } else if (fp.loadedPeriod === 'month') {
        const days = getDaysInMonthRange(fp)
        addClassToDaysRange(days, ['inRange'])
      }
    }

    /**
     * Highlight the days in the selected range.
     */
    function highlightOnSelectedDateRange() {
      if (fp.loadedPeriod === 'week') {
        selectWeek(fp)
        const days = getDaysInWeekRange(fp)
        addClassToDaysRange(days, ['week', 'selected'])
      } else if (fp.loadedPeriod === 'month') {
        selectMonth(fp)
        const days = getDaysInMonthRange(fp)
        addClassToDaysRange(days, ['week', 'selected'])
      }
    }

    /**
     * Add event listeners on hover
     */
    function onReady() {
      if (fp.daysContainer !== undefined) {
        fp.daysContainer.addEventListener('mouseover', highlightOnDayHover)
      }
    }

    /**
     * Remove event listeners on destroy
     */
    function onDestroy() {
      if (fp.daysContainer !== undefined) {
        fp.daysContainer.removeEventListener('mouseover', highlightOnDayHover)
      }
    }

    return {
      onValueUpdate: highlightOnSelectedDateRange,
      onOpen: highlightOnSelectedDateRange,
      onClose: clearHover,
      onReady: [
        () => {
          fp.loadedPeriod = period

          fp.setPeriod = newPeriod => {
            fp.loadedPeriod = newPeriod
          }
        },
        onReady,
        highlightOnSelectedDateRange,
      ],
      onDestroy,
    }
  }
}

export default SpreaadDatePickerPlugin
