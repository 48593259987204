<template>
  <b-navbar-nav class="goal-selector">
    <b-nav-item-dropdown
      id="goal-dropdown"
      class="dropdown-goal"
    >
      <template #button-content>
        <feather-icon
          slot="button-content"
          icon="TargetIcon"
          size="21"
          class="text-primary"
        />
        <b-tooltip
          triggers="hover"
          :target="`goal-dropdown`"
          :title="tooltip"
          :delay="{ show: 1000, hide: 50 }"
        />
        <span
          v-if="currentGoal"
          class="ml-50 text-body"
        >{{ currentGoal.name }}</span>
      </template>
      <b-dropdown-item
        v-for="goal in goals"
        :key="goal.idgoal"
        :active="currentGoal.idgoal === goal.idgoal"
        @click="setGoal(goal)"
      >
        <div>
          <feather-icon
            slot="button-content"
            :icon="goal.icon"
            size="21"
            :alt="goal.name"
            class="text-primary"

            stroke="green"
          />
          <span class="ml-50">{{ goal.name || '' }}</span>
        </div>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>

</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BNavbarNav, BTooltip,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { filterService } from '@/services/spreaadRestApi'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BNavbarNav,
    BTooltip,
  },
  data() {
    return {
      tooltip: 'Goal selector',
      goals: [],
    }
  },
  computed: {
    ...mapGetters('dataFilter', {
      currentGoal: 'getGoal', currentSite: 'getSiteId',
    }),
  },
  created() {
    // Fetch goals
    this.getGoals()

    // subscribe to website changes
    this.$eventBus.$on('websiteChange', () => {
      this.getGoals().then(async () => {
        if (this.goals.length > 0) {
          this.setGoal(this.goals[0], true)
        }
      })
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('websiteChange')
  },
  methods: {
    ...mapActions('dataFilter', [
      'updateGoals',
      'updateGoal',
    ]),
    async getGoals() {
      this.goals = await filterService.getGoals(this.currentSite)
      await this.updateGoals(this.goals)
    },
    setGoal(goal, forceUpdate = false) {
      // Only update graph if the filter has changed
      if (goal.idgoal !== this.currentGoal.idgoal || forceUpdate) {
        // Update filters
        this.updateGoal({ selectedGoal: goal }).then(() => {
          this.$eventBus.$emit('filterChanges')
        })
      }
    },
  },
}
</script>
