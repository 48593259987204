<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <!-- Bookmarks Container -->
      <date-picker v-show="showGoalPicker" />
      <goals v-show="showDatePicker" />
      <site-picker />
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ username }}
              <!-- <span class="user-status">Admin</span> -->
            </p></div>
          <b-avatar
            size="40"
            badge
            class="badge-minimal"
            badge-variant="success"
            variant="light-primary"
          >
            <feather-icon
              icon="UserIcon"
              size="22"
            />
          </b-avatar>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="setDontTrack"
        >
          <feather-icon
            size="16"
            :icon="isUserTrackedIconClass"
            class="mr-50"
          />
          <span>{{ isUserTrackedText }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import DatePicker from './components/DatePicker.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import Goals from './components/Goals.vue'
import SitePicker from './components/SitePicker.vue'

const dontTrackCookie = 'piwik_ignore'
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    // Navbar Components
    DatePicker,
    Goals,
    SitePicker,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isUserTracked: !this.$cookies.get(dontTrackCookie),
    }
  },
  computed: {
    ...mapState('appConfig', {
      showGoalPicker: state => state.layout.navbar.showGoalPicker,
      showDatePicker: state => state.layout.navbar.showDatePicker,
    }),
    ...mapGetters('auth', {
      username: 'getUserName',
    }),
    isUserTrackedText() {
      return this.isUserTracked ? 'Untrackme' : 'Trackme'
    },
    isUserTrackedIconClass() {
      return this.isUserTracked ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    ...mapActions('dataFilter', {
      removeData: 'removeData',
    }),
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token

      // Remove userData from localStorage
      this.removeData()

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    setDontTrack() {
      if (this.isUserTracked) {
        // Set no user tracking cookie
        this.$cookies.set(dontTrackCookie, 'ignore=Kg==', -1, null, '.spreaad.com', null, 'None')
        this.isUserTracked = false
      } else {
        // Remove no user tracking cookie
        this.$cookies.remove(dontTrackCookie, null, '.spreaad.com')
        this.isUserTracked = true
      }
    },
  },
}
</script>
