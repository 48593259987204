/* eslint-disable operator-linebreak */

function getEventTarget(event) {
  try {
    if (typeof event.composedPath === 'function') {
      const path = event.composedPath()
      return path[0]
    }
    return event.target
  } catch (error) {
    return event.target
  }
}

/**
 * @param {*} fp Flatpickr instance
 * Selected the first not disabled day of the selected month
 */
function selectMonth(fp) {
  const days = fp.days.querySelectorAll(
    '.flatpickr-day:not(.nextMonthDay):not(.prevMonthDay):not(.flatpickr-disabled)',
  )

  if (days.length > 0) {
    fp.setDate(days[0].dateObj)
  }
}

/**
 * @param {*} fp Flatpickr instance
 * Selected the first not disabled day of the selected week
 */
function selectWeek(fp) {
  const weekStartDay =
    fp.days.childNodes[7 * Math.floor(fp.selectedDateElem.$i / 7)].dateObj

  const days = fp.days.querySelectorAll(
    '.flatpickr-day:not(.flatpickr-disabled)',
  )

  const weekDays = Array.from(days).filter(day => {
    const date = day.dateObj
    return date >= weekStartDay
  })

  if (weekDays.length > 0) {
    fp.setDate(weekDays[0].dateObj)
  }
}

/**
 * @param {*} fp Flatpickr instance
 * Get the all not disabled days in the current month
 */
function getDaysInMonthRange(fp) {
  const days = fp.days.querySelectorAll(
    '.flatpickr-day:not(.nextMonthDay):not(.prevMonthDay):not(.flatpickr-disabled)',
  )

  return days
}

/**
 * @param {*} fp Flatpickr instance
 * Get the all not disabled days in the given week
 */
function getDaysInWeekRange(fp, startDay = null, endDay = null) {
  const weekStartDay =
    startDay ||
    fp.days.childNodes[7 * Math.floor(fp.selectedDateElem.$i / 7)].dateObj

  const weekEndDay =
    endDay ||
    fp.days.childNodes[7 * Math.ceil(fp.selectedDateElem.$i / 7 + 0.01) - 1]
      .dateObj

  const days = fp.days.querySelectorAll(
    '.flatpickr-day:not(.flatpickr-disabled)',
  )

  const weekDays = Array.from(days).filter(day => {
    const date = day.dateObj
    return date >= weekStartDay && date <= weekEndDay
  })

  return weekDays
}

/**
 * @param {*} days Array of days
 * @param {*} cssClassList Array of css classes
 * Add the css classes to the given days
 */
function addClassToDaysRange(days, cssClassList = []) {
  if (days.length > 0) {
    days.forEach(day => {
      cssClassList.forEach(cssClass => day.classList.add(cssClass))
    })
    days[0].classList.add('first')
    days[days.length - 1].classList.add('last')
  }
}

export {
  getEventTarget,
  selectMonth,
  selectWeek,
  getDaysInMonthRange,
  getDaysInWeekRange,
  addClassToDaysRange,
}
