export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Experiments',
    route: 'acquisition',
    icon: 'ActivityIcon',
  },
  {
    title: 'Visits',
    route: 'visitsLog',
    icon: 'ArchiveIcon',
  },
]
