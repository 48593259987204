<template>
  <b-navbar-nav class="site-selector">
    <b-nav-item-dropdown
      id="site-dropdown"
      class="dropdown-site"
    >
      <template #button-content>
        <feather-icon
          slot="button-content"
          icon="GlobeIcon"
          size="21"
          class="text-primary"
        />
        <b-tooltip
          triggers="hover"
          :target="`site-dropdown`"
          :title="tooltip"
          :delay="{ show: 1000, hide: 50 }"
        />
        <span
          v-if="currentSite"
          class="ml-50 text-body"
        >{{ currentSite.name }}</span>
      </template>
      <template #default>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scroll-area"
          tagname="div"
        >
          <b-dropdown-item
            v-for="site in sites"
            :key="site.idsite"
            :active="currentSite.idsite === site.idsite"
            @click="setSite(site)"
          >
            <div>
              <span class="ml-50">{{ site.name || '' }}</span>
            </div>
          </b-dropdown-item>
        </vue-perfect-scrollbar>
      </template>

    </b-nav-item-dropdown>
  </b-navbar-nav>

</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BNavbarNav, BTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BNavbarNav,
    BTooltip,
    VuePerfectScrollbar,
  },
  data() {
    return {
      tooltip: 'Site selector',
      sites: [],
    }
  },
  computed: {
    currentSite() {
      const current = this.$store.getters['dataFilter/getSite']
      return this.sites.find(site => site.idsite === current.idsite)
    },
  },
  beforeMount() {
    this.sites = this.$store.state.dataFilter.sites
  },
  methods: {
    setSite(site) {
      // Only update graph if the filter has changed
      if (site.idsite !== this.currentSite.idsite) {
        // Update filters
        this.$store.dispatch('dataFilter/updateSite', { selectedSite: site }).then(() => {
          this.$eventBus.$emit('websiteChange')
        })
      }
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }
    return {
      perfectScrollbarSettings,
    }
  },
}
</script>
<style lang="scss" scoped>
.scroll-area{
  max-height: 500px;
}
</style>
