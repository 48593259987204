<template>
  <b-navbar-nav class="nav-date-picker">
    <b-nav-item-dropdown
      id="date-filter"
      ref="dropdown"
      link-classes="bookmark-star"
      menu-class="menu-container-date-picker"
      @hide="onHidden($event)"
    >
      <feather-icon
        slot="button-content"
        icon="CalendarIcon"
        size="21"
        class="text-primary"
      />
      <b-tooltip
        triggers="hover"
        :target="`date-filter`"
        :title="datePicker.tooltip"
        :delay="{ show: 1000, hide: 50 }"
      />

      <!-- Dropdown Content -->
      <li
        class="center"
        style="min-width:300px; max-width: min-content"
      >
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="scroll-area"
          tagname="div"
        >
          <!-- period select -->
          <b-input-group class="shadow-none">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Elige el periodo y la fecha a visualizar"
              class="w-100"
            >
              <b-form-radio-group
                id="btn-radios-2"
                v-model="selectedPeriod"
                :options="datePeriod.options"
                :aria-describedby="ariaDescribedby"
                button-variant="outline-primary"
                name="radio-btn-outline"
                class="w-100"
                buttons
              />
            </b-form-group>
          </b-input-group>

          <!-- date select -->
          <b-input-group class="shadow-none row-1">
            <flat-pickr
              ref="flatPickr"
              v-model="selectedDate"
              :config="getConfig"
              class="form-control flat-picker bg-transparent border-1 shadow-none text-center justify-content-center"
              placeholder="YYYY-MM-DD"
            />
          </b-input-group>
          <b-input-group class="shadow-none">
            <b-button
              variant="primary"
              block
              @click="onClick"
            >Aplicar
            </b-button>
          </b-input-group>
        </vue-perfect-scrollbar>
      </li>
    </b-nav-item-dropdown>
    <span
      class="ml-50 text-body"
    >{{ formatedSelectedDate() + ' / ' + datePeriod.options.find(x => x.value === selectedPeriod).text }}</span>
  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BTooltip, BNavItemDropdown, BButton, BFormRadioGroup, BFormGroup, BInputGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { filterDefaultConfig } from '@/config'
import SpreaadDatePickerPlugin from './flatpickrPlugins/spreaadDatePicker'

export default {
  components: {
    BNavbarNav,
    BTooltip,
    BNavItemDropdown,
    flatPickr,
    BButton,
    VuePerfectScrollbar,
    BFormRadioGroup,
    BFormGroup,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      datePicker: {
        tooltip: 'Select date',
        selected: this.$store.getters['dataFilter/getDate'],
      },
      datePeriod: {
        selected: this.$store.getters['dataFilter/getPeriod'],
        options: [
          { text: 'Day', value: 'day' },
          { text: 'Week', value: 'week' },
          { text: 'Month', value: 'month' },
        ],
      },
      modalCanClose: false,
    }
  },
  computed: {
    selectedSiteCreatedDate() {
      const selectedSite = this.$store.getters['dataFilter/getSite']

      if (!selectedSite || !selectedSite.created) {
        return null
      }
      return new Date(selectedSite.created)
    },
    getConfig() {
      return {
        // mode: 'range',
        mode: 'single',
        inline: true,
        minDate: this.selectedSiteCreatedDate,
        maxDate: filterDefaultConfig.maxDate,
        wrap: false,
        locale: {
          firstDayOfWeek: 1, // start week on Monday
        },
        plugins: [new SpreaadDatePickerPlugin(this.datePeriod.selected)],
        onValueUpdate: value => {
          if (value && value.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.datePicker.selected = value[0].toISOString().split('T')[0]
          }
        },
      }
    },
    selectedDate: {
      get() {
        return this.$store.getters['dataFilter/getDate']
      },
      set(val) {
        // Save on temp variable
        this.datePicker.selected = val
      },
    },
    selectedPeriod: {
      get() {
        return this.$store.getters['dataFilter/getPeriod']
      },
      set(val) {
        // Update period on flatpickr plugin
        this.$refs.flatPickr.fp.setPeriod(val)
        // Save on temp variable
        this.datePeriod.selected = val
      },
    },
  },
  methods: {
    formatedSelectedDate() {
      if (this.selectedPeriod === 'week') {
        const weekStartDay = new Date(this.selectedDate)
        const weekStartDayString = this.selectedDate

        const weekEndDay = new Date(weekStartDay.setDate(weekStartDay.getDate() + 6))
        const weekEndDayString = weekEndDay.toISOString().split('T')[0]

        return `${weekStartDayString}-${weekEndDayString}`
      } if (this.selectedPeriod === 'month') {
        const year = this.selectedDate.split('-')[0]
        const month = this.selectedDate.split('-')[1]
        return `${year}-${month}`
      }
      return this.selectedDate
    },
    onHidden(bvEvent) {
      // Force to close using button, this prevent undesired behaviour
      if (this.modalCanClose) {
        this.modalCanClose = false
        return true
      }
      return bvEvent.preventDefault()
    },
    onClick() {
      // Only update graph if the filter has changed
      if (
        this.datePicker.selected !== this.selectedDate
        || this.datePeriod.selected !== this.selectedPeriod
      ) {
        this.$store.dispatch('dataFilter/updatePeriod', {
          date: this.datePicker.selected,
          period: this.datePeriod.selected,
        }).then(() => {
          this.$eventBus.$emit('filterChanges')
        })
      }

      // Close the menu and (by passing true) return focus to the toggle button
      this.modalCanClose = true
      this.$refs.dropdown.hide()
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }
    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.flatpickr-day.week.selected {
    background: #325ff6;
    box-shadow: -5px 0 0 #325ff6, 5px 0 0 #569ff7 !important;
}

.flatpickr-day.week.selected.first{
    border-top-left-radius: 150px !important;
    border-bottom-left-radius: 150px !important;
    box-shadow: none !important;
}

.flatpickr-day.week.selected.last{
    border-top-right-radius: 150px !important;
    border-bottom-right-radius: 150px !important;
    box-shadow: -5px 0 0 #325ff6 !important;
}

.flatpickr-day.inRange{
    background-color: #e6e6e6 !important;
    box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6 !important;

    border-top: 1px #e6e6e6 solid !important;
    border-bottom: 1px #e6e6e6 solid !important;
    border-left: none;
    border-right: none;
}

.flatpickr-day.inRange.selected{
    background-color: #325ff6  !important;
    box-shadow: -5px 0 0 #325ff6, 5px 0 0 #569ff7 !important;

    border-top: 1px #325ff6 solid !important;
    border-bottom: 1px #325ff6 solid !important;
    border-left: none;
    border-right: none;
}

.flatpickr-day.inRange.first:not(.selected ){
    border-top-left-radius: 150px !important;
    border-bottom-left-radius: 150px !important;
    box-shadow: none !important;
}

.flatpickr-day.inRange.last:not(.selected ){
    border-top-right-radius: 150px !important;
    border-bottom-right-radius: 150px !important;
    box-shadow: none !important;
}
</style>
