<template>
  <layout-vertical>

    <b-overlay
      :show="overlayOn"
    >
      <router-view />
    </b-overlay>

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div
      slot="breadcrumb"
    >
      <AppBreadcrumb />
    </div>

    <div slot="footer">
      <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
          Copyright © {{ new Date().getFullYear() }} Spreaad
          <span class="d-none d-sm-inline-block">, All rights Reserved</span>
        </span>
      </p>
    </div>
    <!--
    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
    -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import Navbar from '../components/app-navbar/Navbar.vue'
import AppBreadcrumb from '../components/AppBreadcrumb.vue'
import { BOverlay } from 'bootstrap-vue'
import { ON_STOP_OVERLAY, ON_START_OVERLAY } from '@/libs/eventBusConstants'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    AppBreadcrumb,
    BOverlay,
  },
  data() {
    return {
      overlayOn: true,
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  created() {
    this.$eventBus.$on(ON_START_OVERLAY, () => {
      this.overlayOn = true
    })
    this.$eventBus.$on(ON_STOP_OVERLAY, () => {
      this.overlayOn = false
    })
  },
  beforeDestroy() {
    this.$eventBus.$off(ON_START_OVERLAY)
    this.$eventBus.$off(ON_STOP_OVERLAY)
  },
}
</script>
